import React from 'react';
import firebase, {provider} from '../../firebase';

class SignIn extends React.Component {
  state = {
    email: '',
    password: '',
    error : null,
    forgotPasswordClicked: false,
    emailSentConfirmation: false,
  }

  handleInputChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  handleForgotPasswordClick = () => {
    this.setState({
      forgotPasswordClicked: true,
      error: null
    });
  }

  handleLogin = (e) => {
    e.preventDefault();
    this.setState({error: null});

    firebase.auth().signInWithPopup(provider)
    .then((result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // var token = result.credential.accessToken;
        // // The signed-in user info.
        // var user = result.user;
        // ...
    }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        this.setState({error})
        // ...
        console.log(errorCode,errorMessage)
    });
  }

  handleSignUp = () => {
    const {email, password} = this.state;
    this.setState({error: null});

    firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((result) => {
        // var token = result.user.refreshToken;
        // // The signed-in user info.
        // var user = result.user;
        // ...
    })
    .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        this.setState({error})
        // ...
        console.log(errorCode,errorMessage)
    });
}

handleSignIn = (e) => {
    e.preventDefault();
    const {email, password} = this.state;

    this.setState({error: null});

    firebase.auth().signInWithEmailAndPassword(email, password)
    .then((result) => {
        // var token = result.user.refreshToken;
        // // The signed-in user info.
        // var user = result.user;
        // ...
    })
    .catch((error) => {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        this.setState({error})
        // ...
    });
}

  handleSendResetPassword = () => {
    const {email} = this.state;
    this.setState({error: null});

    firebase.auth().sendPasswordResetEmail(email).then(() => {
      // Email sent.
      this.setState({emailSentConfirmation: true});
    }).catch((error) => {
      // An error happened.
      this.setState({error});
    });
  }

  render() {
    const { email, password, error, forgotPasswordClicked, emailSentConfirmation } = this.state;
    return (
      <React.Fragment>
      { forgotPasswordClicked ?
        <React.Fragment>
        <div className="form-group">
          <input type="text" className="form-control" placeholder="Email" name="email" value={email} onChange={this.handleInputChange} />
        </div>
        <div className="form-group">
          <button className="btn btn-warning btn-block" onClick={this.handleSendResetPassword}>Send Email</button>
        </div>
        {error && error.message && <div className="alert alert-danger">{error.message}</div>}
        {emailSentConfirmation && <div className="alert alert-success">Email sent. Please check your inbox.</div>}
        </React.Fragment>
      :
      <form onSubmit={this.handleSignIn}>
        <div className="form-group text-center">
            <button className="btn btn-google mb-1" onClick={this.handleLogin}>
            <i className="fab fa-google"></i> Sign in with Google</button>
        </div>
        <div className="text-center mb-3">
            <span className="small">or</span>
        </div>
        <div className="form-group">
            <input type="text" className="form-control" placeholder="Email" name="email" value={email} onChange={this.handleInputChange} />
        </div>
        <div className="form-group">
            <input type="password" className="form-control" placeholder="Password" name="password" value={password} onChange={this.handleInputChange}/>
            <span id="forgotPasswordText" className="small" onClick={this.handleForgotPasswordClick}><u>Forgot password?</u></span>
        </div>
        <div className="form-group">
            <button className="btn btn-primary btn-block" onClick={this.handleSignIn}>Sign In</button>
        </div>
        {error && error.message && <div className="alert alert-danger">{error.message}</div>}
      </form>
      }
      </React.Fragment>
    );
  }
}

export default SignIn;