import React from 'react';
import { PayPalButton } from "react-paypal-button-v2";

const CLIENT = {
    sandbox: 'AZKBjcTezFf4zc2Yxa1SkLzfVeH3ovQ7P5WxWJBYpVSAyCuQexuAbBB05G00tPEpBx_Hmpp3dgivAiNz',
    production: 'Ac3voORI9so6k8Od6lSTe1-8RHL7iuWrgFD7Nq-Yp3jyHyHhbovc23KOMTrDDLamT1T4x2hPNDRsJa8B',
  };
  
  const ENV = process.env.NODE_ENV === 'production'
    ? 'production'
    : 'sandbox';

class PaypalCheckout extends React.Component {
    
    render() {
        const {amount} = this.props;

        return(
            <div className="checkout">
                
                <PayPalButton
                    // amount="0.01"
                    // currency="RUB"
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                            amount: {
                                // currency_code: "EUR",
                                value: amount
                            }
                            }]
                        });
                    }}
                    // onApprove={(data, actions) => {
                    //     // Capture the funds from the transaction
                    //     return actions.order.capture().then(function(details) {
                    //       // Show a success message to your buyer
                    //       console.log('onApprove Transaction completed by ' + details.payer.name.given_name, details);
                    //     });
                    // }}
                    onSuccess={this.props.onSuccess}
                    options={{
                        currency:"EUR",
                        clientId: CLIENT[ENV],
                    }}
                />
            </div>
        )
    }
}

export default PaypalCheckout;