import React, { Component } from 'react';
import firebase from '../../firebase';

class Navbar extends Component {
    render() {
        const {user, dbUser } = this.props;
        const isSubscriptionActive = dbUser && dbUser['subscriptionTill'] && dbUser['subscriptionTill'].toDate() > new Date();
        let badgeText;
        if (isSubscriptionActive) {
          badgeText = subscriptionTypes[dbUser['subscriptionType']]
        }

        return (
            <nav className="navbar navbar-expand-lg navbar-light border-bottom" id="mainNav">
            <div className="container">

              <div className="navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item logo">
                    Split My Excel File
                  </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    {user ? 
                    <React.Fragment>
                      <span className="small">Hello, {user.displayName || user.email}</span>
                      {isSubscriptionActive && <span className="badge badge-warning mx-1">{badgeText}</span>}
                    <button className="btn btn-outline-secondary ml-3" onClick={() => firebase.auth().signOut()}>
                        Sign Out
                    </button>
                    </React.Fragment>
                    : 
                    <React.Fragment>
                      <button className="btn btn-outline-warning mr-3" onClick={this.props.handleSignUp}>Sign Up</button>
                      <button className="btn btn-warning" onClick={this.props.handleSignIn}>Sign In</button>
                    </React.Fragment>
                    }
                </li>
                </ul>
              </div>
            </div>
          </nav>
        )
    }
}

export default Navbar;

const subscriptionTypes = {
  '4.00': '1 Day',
  '9.00': '1 Week',
  '14.00': '1 Year'
}