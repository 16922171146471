import React from 'react';
import firebase, { db } from '../../firebase';
import { AuthContext } from '../../auth-context';

import SplitMyExcelFile from '../../components/SplitMyExcelFile';
import PaypalCheckout from '../../components/PaypalCheckout';

class Home extends React.Component {
  state = {
      amount: '9.00',
      numDownloadsToday: 0,
      numRows: 0
  }

  componentDidMount() {
    const date = new Date().toDateString();
    const dlnum = localStorage.getItem('dl');
    if (dlnum) {
      const numDownloadsToday = JSON.parse(dlnum)[date];
      if (numDownloadsToday) this.setState({numDownloadsToday});
      if (numDownloadsToday >= MAX_DOWNLOADS_PER_DAY) {
        const message = 'Thank you for using Splitmyexcelfile. If you want to split more files, please sign up for a subscription.'
        this.props.onReachLimit(message);
      }
    }
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({amount: value})
  }
  onPaymentSuccess = (details, data) => {
    console.log("onSuccess Transaction completed by " + details.payer.name.given_name, details, data);
    const user = firebase.auth().currentUser;
    let daysToSubscriptionEnd;
    const { amount } = this.state;
    switch (amount) {
        case '4.00': daysToSubscriptionEnd = 1;
        break;
        case '9.00': daysToSubscriptionEnd = 7;
        break;
        case '14.00': daysToSubscriptionEnd = 365;
        break;
        default: break;
    } 
    db.collection('users').doc(user.uid).set({
        subscriptionTill: addDays(new Date(), daysToSubscriptionEnd),
        subscriptionType: amount,
    }, {merge: true})
    .then(() => {
      return db.collection('payments').add({
        data,
        details,
        userId: user.uid
      })
    })
    .then(() => {
      this.props.onPaymentCompleted();
    })
  }

  onDownloadComplete = () => {
    let { numDownloadsToday } = this.state;
    const date = new Date().toDateString();
    localStorage.setItem('dl', JSON.stringify({[date]: ++numDownloadsToday}));
    let message;
    if (numDownloadsToday >= MAX_DOWNLOADS_PER_DAY) {
      message = 'Thank you for using Splitmyexcelfile. If you want to split more files, please sign up for a subscription.'
      this.props.onReachLimit(message);
    } 
    this.setState({numDownloadsToday})
  }

  onUploadComplete = (numRows) => {
    const { numDownloadsToday } = this.state;
    let message;
    if (numDownloadsToday >= MAX_DOWNLOADS_PER_DAY) {
      message = 'Thank you for using Splitmyexcelfile. If you want to split more files, please sign up for a subscription.'
      this.props.onReachLimit(message);
    } else if (numRows > MAX_ROWS_PER_FILE) {
      message = 'This file exceeds the free row limit. If you want to split large files, please sign up for a subscription.'
      this.props.onReachLimit(message);
    }
    this.setState({numRows});
  }

  render() {
    const { amount, numDownloadsToday, numRows } = this.state;
    const { message, isSubscriptionActive } = this.props;
    const user = firebase.auth().currentUser;
    const hasReachedLimit = numDownloadsToday >= MAX_DOWNLOADS_PER_DAY || numRows > MAX_ROWS_PER_FILE;
    return (
      <div className="home">
        <SplitMyExcelFile 
          isSubscriptionActive={isSubscriptionActive}
          hasReachedLimit={hasReachedLimit}
          onDownloadComplete={this.onDownloadComplete}
          onUploadComplete={this.onUploadComplete}
        />
        {!isSubscriptionActive &&
        <section className="bg-light pb-4">
          <div className="container">
          <div className="row">
              <div className="col-sm-4 offset-sm-5">
              {user && message && <div className="alert alert-info">
              <i className="fas fa-info-circle"></i> {message} Please choose a product to continue
            </div>}
            {!user && message && <div className="alert alert-info">
              <i className="fas fa-info-circle"></i> {message} Sign up and choose a product to get full access and download the split files
            </div>}
              {user && 
              <React.Fragment>
                <select 
                    value={amount} 
                    className="form-control mb-3" 
                    onChange={this.handleChange}
                    name="amount"
                >
                    <option value="4.00">Full access 24 hours €4,00 EUR</option>
                    <option value="9.00">Full access 1 week €9,00 EUR</option>
                    <option value="14.00">Full access 1 year €14,00 EUR</option>
                </select>
                  <PaypalCheckout 
                    amount={amount}
                    onSuccess={this.onPaymentSuccess} 
                  />
              </React.Fragment>
              }
              </div>
          </div>
          </div>
        </section>
        }
      </div>
    );
    }
  }
  
  Home.contextType = AuthContext;
  export default Home;

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const MAX_DOWNLOADS_PER_DAY = 1;
const MAX_ROWS_PER_FILE = 30000;