import React from 'react';
import {useDropzone} from 'react-dropzone';

function DropZone(props) {
  const {getRootProps, getInputProps} = useDropzone({
    accept: '.xlsx, .xls, .csv',
    multiple: false,
    onDropAccepted: props.onDropAccepted
    // getFilesFromEvent: props.onDropAccepted
  });

  return (
    <React.Fragment>
      <div {...getRootProps({className: 'dropzone text-center'})}>
        <input {...getInputProps()} />
        <div className="cloud-icon"><img src="cloud.png" className="img-fluid" alt="Upload" /></div>
        <div><span className="h4">1.</span> Drop Excel file or <button className="btn btn-primary ml-2">Select file</button></div>
      </div>
      </React.Fragment>
  );
}

export default DropZone;