import React from 'react';
import firebase, {provider} from '../../firebase';

class SignUp extends React.Component {
  state = {
    email: '',
    password: '',
    password2: '',
    error : null
  }

  handleInputChange = e => {
    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  handleLogin = (e) => {
    e.preventDefault();
    this.setState({error: null});

    firebase.auth().signInWithPopup(provider)
    .then((result) => {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // var token = result.credential.accessToken;
        // // The signed-in user info.
        // var user = result.user;
        // ...
    }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        this.setState({error})
        // ...
        console.log(errorCode,errorMessage)
    });
  }

  validate = () => {
    const { email, password, password2 } = this.state;
    const error = {};
    if (!/\S+@\S+\.\S+/.test(email)) {
      error['message'] = 'Incorrect email address';
    } else if (!password || password.length < 6) {
      error['message'] = 'Password too short';
    } else if (password !== password2) {
      error['message'] = 'Your password and confirmation password do not match';
    }
    if (error.message) {
      this.setState({ error });
      return error
    }

    return null
  }

  handleSignUp = (e) => {
    e.preventDefault();
    const {email, password} = this.state;
    this.setState({error: {}});

    const error = this.validate();

    if (!error) {
      firebase.auth().createUserWithEmailAndPassword(email, password)
      .then((result) => {
          // var token = result.user.refreshToken;
          // // The signed-in user info.
          // var user = result.user;
          // ...
      })
      .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          this.setState({error})
          // ...
          console.log(errorCode,errorMessage)
      });
    }
  }

  render() {
    const { email, password, password2, error } = this.state;
    const { message } = this.props;
    return (
      <form onSubmit={this.handleSignUp}>
        {message && <div className="alert alert-info">
          <i className="fas fa-info-circle"></i> {message}
        </div>}
        <div className="form-group text-center">
            <button className="btn btn-google mb-1" onClick={this.handleLogin}>
            <i className="fab fa-google"></i> Sign in with Google
            </button>
        </div>
        <div className="text-center mb-3">
            <span className="small">or</span>
        </div>
        <div className="form-group">
            <input type="text" className="form-control" placeholder="Email" name="email" value={email} onChange={this.handleInputChange} />
        </div>
        <div className="form-group">
            <input type="password" className="form-control" placeholder="Password" name="password" value={password} onChange={this.handleInputChange}/>  
        </div>
        <div className="form-group">
            <input type="password" className="form-control" placeholder="Repeat Password" name="password2" value={password2} onChange={this.handleInputChange}/>  
        </div>
        <div className="form-group">
            <button className="btn btn-warning btn-block" onClick={this.handleSignUp}>Sign Up</button>
        </div>
        <span id="forgotPasswordText" className="small" onClick={this.props.handleHaveAccountClick}><u>Already have an account?</u></span>
        {error && error.message && <div className="alert alert-danger">{error.message}</div>}
      </form>
    );
  }
}

export default SignUp;