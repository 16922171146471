import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
 
 // Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyASsyDl6TVcIYlzoV2GvKLvIsd1dfBlGOY",
    authDomain: "splitmyexcelfile.firebaseapp.com",
    databaseURL: "https://splitmyexcelfile.firebaseio.com",
    projectId: "splitmyexcelfile",
    storageBucket: "splitmyexcelfile.appspot.com",
    messagingSenderId: "36604161341",
    // appId: "1:36604161341:web:635d3bcf23f494d9"
  };

  firebase.initializeApp(firebaseConfig);

  const provider = new firebase.auth.GoogleAuthProvider();
  const db = firebase.firestore();

  export {
    provider,
    db,
    firebase as default
}