import React from 'react';
import XLSX from 'xlsx';
// import JSZip  from 'jszip';
import FileSaver from 'file-saver';

import DropZone from '../DropZone';
import Worker from 'worker-loader!../../workers/appWorker'; // eslint-disable-line import/no-webpack-loader-syntax
import {MAX_ROWS} from '../../workers/constants';

import './style.css';

let webWorker;

class SplitMyExcelFile extends React.Component {
    state = {
        workbook: null,
        wbJson: null,
        sheetName: null,
        filePath: null,
        fileSize: null,
        numRows: null,
        splitInto: {
            num: '',
            splitType: 'Rows per file',
        },
        numHeaderRows: 1,
        headerOrder: [],
        loading: false,
    }

    componentDidMount() {
        webWorker = new Worker();
        webWorker.postMessage({ data: "Data from component"});
        webWorker.addEventListener('message', (event) => {
            const {type, data} = event.data;
            if (type === 'FILE_READ') {
                const {numRows, headerOrder, sheetName} = data;
                this.setState({
                    ...this.state,
                    sheetName,
                    numRows,
                    headerOrder,
                    loading: false
                });
                this.props.onUploadComplete(numRows);
            } else if (type === 'SPLIT_PART_COMPLETE') {
                const {workbook, fileNameOfPart} = data;
                XLSX.writeFile(workbook, fileNameOfPart);
            } else if (type === 'SPLIT_COMPLETE') {
                const {filePath} = this.state;
                const fileName = filePath.substr(0, filePath.lastIndexOf('.'));
                FileSaver.saveAs(data, `${fileName}.zip`);
            }
        });
    }

    componentWillUnmount() {
        if (webWorker) {
            webWorker.terminate();
        }
    }

    handleDeleteFile = () => {
        this.setState({
            workbook: null,
            wbJson: null,
            sheetName: null,
            filePath: null,
            fileSize: null,
            numRows: null,
        });
    }

    handleInputChange = e => {
        const { name, value } = e.target;

        this.setState({
            splitInto: {
                ...this.state.splitInto,
                [name]: parseInt(value, 10) || '',
            }
        });
    }

    handleSplitTypeChange = (splitType) => {
        this.setState({
            splitInto: {
                ...this.state.splitInto,
                splitType
            }
        });
    }

    handleOptionsInputChange = e => {
        const { name, value } = e.target;
        this.setState({
            [name]: value ? parseInt(value, 10) : ''
        })
    }

    handleDownloadClick = () => {
        const { numRows, filePath, sheetName, splitInto, numHeaderRows, headerOrder } = this.state;

        webWorker.postMessage({
            type: 'SPLIT_START',
            data: {
                splitInto,
                numRows,
                numHeaderRows,
                fileName: filePath.substr(0, filePath.lastIndexOf('.')),
                sheetName,
                headerOrder
            }});

        this.props.onDownloadComplete();
    }

    onDropAccepted = (files) => {
        const file = files[0];

        webWorker.postMessage({type: 'FILE_ACCEPTED', data: file});
        this.setState({
            ...this.state,
            filePath: file.path,
            fileSize: Math.ceil(file.size / 1024),
            loading: true
        })
    }

    getFilePartsList() {
        const {numRows, filePath} = this.state;
        let { splitType, num } = this.state.splitInto;
        if (!num || !numRows || !filePath) {
            return [];
        }
        let rowsPerFile;
        // , numFiles;
        if (splitType === 'Rows per file') {
            // numFiles = Math.ceil(numRows / num);
            rowsPerFile = num;
        } else {
            rowsPerFile = Math.floor(numRows / num);
            // numFiles = num
        }

        let filenames = [];
        const fileName = filePath.substr(0, filePath.lastIndexOf('.'));
        for (let i = 0; i < numRows; i+= rowsPerFile) {
            const fileNameOfPart = `${fileName}_part(${i}-${i + rowsPerFile < numRows ? i + rowsPerFile : numRows}).xlsx`;
            filenames.push(fileNameOfPart);

        }
        if (filenames.length > 12) {
            filenames = filenames.slice(0, 12);
        }
        return filenames;
    }

    handleDownloadPart(index) {
        return () => {
            const { numRows, filePath, sheetName, splitInto, numHeaderRows, headerOrder } = this.state;
            webWorker.postMessage({
                type: 'SPLIT_PART_START',
                data: {
                    index,
                    splitInto,
                    numRows,
                    numHeaderRows,
                    fileName: filePath.substr(0, filePath.lastIndexOf('.')),
                    sheetName,
                    headerOrder
                }});

            this.props.onDownloadComplete();
        }
        
    }

    render() {
        const { filePath, fileSize, numRows, splitInto, numHeaderRows, loading } = this.state;
        const {hasReachedLimit, isSubscriptionActive} = this.props;
        let numFiles = null;
        if (splitInto.numFiles || (numRows && splitInto.rowsPerFile)) {
            numFiles = splitInto.numFiles || Math.ceil(numRows / splitInto.rowsPerFile);
        }

        const fileNamesParts = this.getFilePartsList();

        return (
            <React.Fragment>
                <section className="container text-center mt-4">
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2">
                        <h1>Just <span className="text-primary">split my Excel</span> file for me</h1>
                        <p>Too many rows in the Excel file you are trying to import? Why solve the problem if you can go around it with this simple tool? It's free</p>
                        </div>
                    </div>
                </section>
                <section className="container">
                <div className="row">
                    <div className="col-sm-6 offset-sm-3">
                        <div className="card shadow  p-1">
                            <div className="card-body">
                            {loading ?
                            <div className="dropzone d-flex justify-content-center align-items-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            :
                            
                            numRows ? 
                            <div className="dropzone">
                                <div className="bg-light p-3">
                                    <i className="far fa-file-excel mr-2"></i>
                                        {filePath}
                                        <button className="btn btn-light ml-4" onClick={this.handleDeleteFile}>
                                            <i className="far fa-trash-alt text-danger"></i>
                                        </button>
                                </div>
                                File type: {filePath.substr(filePath.lastIndexOf('.'))} <br/>
                                Size: {fileSize} Kilobytes <br/>
                                Rows: {numRows}
                            </div>
                            :
                            <DropZone onDropAccepted={this.onDropAccepted}/>
                            }
                                
                                <div className="row mb-2 mt-2">
                                    <div className="col-sm-12 mb-1">
                                        <b>Header rows</b>
                                    </div>
                                    <div className="col-sm-2">Copy </div>
                                    <div className="col-sm-3">
                                        <input 
                                            type="text"
                                            className="form-control"
                                            name="numHeaderRows"
                                            value={numHeaderRows}
                                            onChange={this.handleOptionsInputChange}
                                        />
                                    </div>
                                    <div className="col-sm-7">
                                        header rows into each file
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                </section>
                <section className="bg-light py-4 mt-5">
                    <div className="container">
                    <div className="row mb-2 mt-4">
                        <div className="col-sm-6 offset-sm-3">
                            <span className="font-weight-bold">2. Split into</span> 
                            <div className="row mt-2 mb-2">
                                <div className="col-sm-12 ">
                                        <button 
                                            className={`btn rounded-pill mr-2 px-3 ${splitInto.splitType === 'Rows per file' ? 'btn-primary': 'btn-outline-secondary'}`}
                                            onClick={() => this.handleSplitTypeChange('Rows per file')}
                                        >
                                            Rows per file
                                        </button>
                                        <button 
                                            className={`btn rounded-pill mr-2 px-5 ${splitInto.splitType === 'Files' ? 'btn-primary': 'btn-outline-secondary'}`} 
                                            onClick={() => this.handleSplitTypeChange('Files')}
                                        >
                                            Files
                                        </button>
                                </div>
                                
                            </div>
                            <div className="row mt-2 mb-2">
                                <div className="col-sm-4">
                                        <input 
                                            type="text"
                                            className="form-control"
                                            name="num"
                                            placeholder="Enter number"
                                            value={splitInto.num}
                                            onChange={this.handleInputChange}
                                        /> 
                                        
                                </div>
                                <div className="col-sm-5">
                                    <label className="mt-2">{splitInto.type}</label>
                                </div>
                            </div>
                        </div> 
                    </div>
                    {hasReachedLimit && !isSubscriptionActive ?
                    null
                    :
                    numRows && numRows > MAX_ROWS ?
                        <div className="row">
                            <div className="col-sm-4 offset-sm-5 card shadow p-1">
                                <div className="card-body">
                                    Download parts
                                    {fileNamesParts.length > 0 ?
                                    fileNamesParts.map((filename, i) => (
                                        <div key={i} className="bg-light p-1 mb-1 d-flex">
                                            <i className="far fa-file-excel mt-1 mr-2"></i>
                                            <div className="small">
                                                <div>Part {i+1}</div>
                                                <div className="text-break">{filename}</div>
                                            </div>
                                            <button className="btn btn-light ml-4" onClick={this.handleDownloadPart(i)}>
                                                <i className="fas fa-download text-success"></i>
                                            </button>
                                        </div>
                                    ))
                                    : 
                                    <div className="alert alert-info">Please enter split options.</div>
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-sm-1 offset-sm-5 text-right">
                                <span className="h4">3. </span>
                            </div>
                                <div className="col-sm-3">
                                    <button 
                                        disabled={hasReachedLimit && !isSubscriptionActive}
                                        className="btn btn-success btn-block" 
                                        onClick={this.handleDownloadClick}
                                    >
                                        <i className="fas fa-download"></i> Download {numFiles} files
                                    </button>
                                </div>
                        </div>
                    }
                    </div>
                </section>
            </React.Fragment>
        )
    }
}

export default SplitMyExcelFile;