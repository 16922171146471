import React from 'react';
import firebase, { db } from './firebase';
import { Modal, ModalBody } from 'reactstrap';

import Navbar from './layout/Navbar';
import Home from './pages/Home';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';

import { AuthContext } from './auth-context';

import './bootstrap-overrides.scss'
import './app.scss';

class App extends React.Component {
  state = {
    isSignedIn: false,
    user: null,
    isLoginModalopen: false,
    isSignUpModalOpen: false,
    dbUser: null,
    isSubscriptionActive: false,
    message: ''
  }
  
  componentDidMount() {
    // Updating the `isSignedIn` and `userProfile` local state attributes when the Firebase Auth
    // state changes.
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      this.setState({ 
        isSignedIn: !!user, 
        user,
        isLoginModalopen: false,
        isSignUpModalOpen: false,
      }, () => {
        if (this.state.user) {
          this.loadDBUser(this.state.user.uid)
        }
      });
    });
  }
  
  componentWillUnmount() {
    // Un-registers the auth state observer.
    this.unregisterAuthObserver();
  }

  loadDBUser = (uid) => {
    db.collection('users').doc(uid).get()
    .then(doc => {
      if (doc.exists) {
        const dbUser = doc.data();
        const isSubscriptionActive = dbUser['subscriptionTill'] && dbUser['subscriptionTill'].toDate() > new Date();
        
        this.setState({ dbUser, isSubscriptionActive}, () => {
          let message = 'Thank you for using Splitmyexcelfile. If you want to split more files, please sign up for a subscription.';
          if (!isSubscriptionActive) this.onReachLimit(message)
        });
      } else {
        this.createDBUser(uid);
      }
    })
  }

  createDBUser = (uid) => {
    const userData = {
      subscriptionTill: null,
      subscriptionType: ''
    }
    db.collection('users').doc(uid).set(userData)
    .then(doc => {
      this.loadDBUser(uid);
    })
  }

  toggleLoginModal = () => {
    this.setState(prevState => ({
      isLoginModalopen: !prevState.isLoginModalopen
    }));
  }

  toggleSignUpModal = () => {
    this.setState({isSignUpModalOpen: !this.state.isSignUpModalOpen})
  }

  handleHaveAccountClick = () => {
    this.setState({
      isSignUpModalOpen: false,
      isLoginModalopen: true
    })
  }

  onReachLimit = (message) => {
    this.setState({
      isSignUpModalOpen: !this.state.isSignedIn,
      message
    })
  }

  onPaymentCompleted = () => {
    this.setState({isSubscriptionActive: true});
  }

  render () {
    return (
        <div className="App">
          <AuthContext.Provider value={this.state.dbUser}>
            <Navbar 
              user={this.state.user}
              dbUser={this.state.dbUser}
              handleSignIn={this.toggleLoginModal}
              handleSignUp={this.toggleSignUpModal}
            />
            <Home 
              onReachLimit={this.onReachLimit}
              message={this.state.message}
              isSubscriptionActive={this.state.isSubscriptionActive}
              onPaymentCompleted={this.onPaymentCompleted}
             />
          </AuthContext.Provider>
          <Modal 
            isOpen={this.state.isLoginModalopen} 
            toggle={this.toggleLoginModal}
            className="login-modal"
          >
            <ModalBody className="p-4">
              <SignIn />
            </ModalBody>
          </Modal>
          <Modal 
            isOpen={this.state.isSignUpModalOpen} 
            toggle={this.toggleSignUpModal}
            className="signup-modal"
          >
            <ModalBody className="p-4">
              <SignUp 
                message={this.state.message}
                handleHaveAccountClick={this.handleHaveAccountClick}
              />
            </ModalBody>
          </Modal>
        </div>
      );
    }
}

export default App;
